// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");

// require("packs/settings");
require("packs/app");

window.currency = require("currency.js");


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
feather = require("packs/feather.min.js");
document.addEventListener("turbolinks:load", function () {
    feather.replace();
});

function allowOnlyTwoCharactersAfterComa(value) {
    if (value.indexOf(",") >= 0) {
        return value.substr(0, value.indexOf(",")) + value.substr(value.indexOf(","), 3);
    } else {
        return value
    }
}

function allowOnlyNumbersAndComas(value) {
    return value.replace(/[^0-9.,-]/g, '')
}

function replaceDotWithComa(value) {
    return value.replace(/\./g, ',')
}

function groupNumbersByThree(value) {
    let parts = value.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return parts.join(".");
}

window.readableToFloat = function readableToFloat(readable) {
    return readable.replace(/ /g, '').replace(/,/g, '\.')
}

window.formatNumberInput = function formatNumberInput(input) {
    let value = input
    value = allowOnlyNumbersAndComas(value)
    value = replaceDotWithComa(value)
    value = allowOnlyTwoCharactersAfterComa(value)
    return groupNumbersByThree(value)

}